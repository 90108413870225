import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Unit } from "types";

const minuteInMiliseconds = 60 * 1000;

export default function ListItem({ unit, handleUnitClick }: { unit: Unit; handleUnitClick: () => void }) {
  const { t } = useTranslation();

  const [lastRefresh] = useState(new Date().valueOf());
  const [minutesSinceLastRefresh, setMinutesSinceLastRefresh] = useState(0);

  const lastSeen = useMemo(() => {
    if (unit.lastSeenUtc.includes("0001-01-01")) {
      return t("List.Never");
    }

    let lastSeenTimestamp = new Date(`${unit.lastSeenUtc}Z`);
    let now = new Date();

    if (minutesSinceLastRefresh >= 2) {
      // More than 2 minutes since page was refreshed - timestamp is no longer up to date, so show nothing
      return "";
    }

    if (now.valueOf() - lastSeenTimestamp.valueOf() < minuteInMiliseconds * 2) {
      return t("List.Now");
    }

    if (lastSeenTimestamp.getFullYear() === now.getFullYear() && lastSeenTimestamp.getMonth() === now.getMonth() && lastSeenTimestamp.getDate() === now.getDate()) {
      // Today - show timestamp only
      return lastSeenTimestamp.toLocaleTimeString();
    }

    // We last saw the terminal yesterday or before - show full date time
    return lastSeenTimestamp.toLocaleString();
  }, [unit.lastSeenUtc, minutesSinceLastRefresh, t]);

  // Keeping track of time since last page refresh
  useEffect(() => {
    function updateLastRefreshTime() {
      let now = new Date().valueOf();
      setMinutesSinceLastRefresh(Math.floor((now - lastRefresh) / minuteInMiliseconds));
    }

    let interval = setInterval(updateLastRefreshTime, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [lastRefresh]);

  return (
    <div className="list-item" onClick={handleUnitClick}>
      <p>{unit.name}</p>
      <p>{lastSeen}</p>
    </div>
  );
}
