export default class HttpSuccessResponse<T> {
  data: T;

  constructor(data: T) {
    this.data = data;
  }

  isSuccess(): this is HttpSuccessResponse<T> {
    return true;
  }
}
