import { useCallback, useState } from "react";

import BackendService from "services/backend";

import { Patch, Unit, UnitSetting } from "types";

function getPatches({ keys, values }: { keys: string[]; values: Object }): Patch[] {
  return keys.map((patchKey: string) => {
    let key = patchKey as keyof typeof values;
    let value = values[key];
    return { op: "replace", path: `/${patchKey}`, value: value };
  });
}

function getPatchKeys(keys: string[], object1: Object, object2: Object) {
  return keys.filter((key) => {
    return object1[key as keyof Object] !== object2[key as keyof Object];
  });
}

export default function useEditActions() {
  const [setupCode, setSetupCode] = useState<string | null>(null);

  async function handleUnitSave({ defaultUnit, editedUnit }: { defaultUnit: Partial<Unit>; editedUnit: Partial<Unit> }): Promise<string> {
    if (defaultUnit.id) {
      // PATCH existing
      const patchKeys = getPatchKeys(["name"], defaultUnit, editedUnit);
      if (patchKeys.length) {
        let patchResponse = await BackendService.updateUnit(defaultUnit.id, getPatches({ keys: patchKeys, values: editedUnit }));
        if (!patchResponse.isSuccess()) {
          throw new Error("Failed to update unit");
        }
      }
      return defaultUnit.id;
    } else {
      // POST new
      let postResponse = await BackendService.createUnit(editedUnit as Unit);
      if (!postResponse.isSuccess()) {
        throw new Error("Failed to create new unit");
      } else {
        return postResponse.data.id;
      }
    }
  }

  async function handleUnitSettingSave({ unitId, defaultUnitSetting, editedUnitSetting }: { unitId: string; defaultUnitSetting: Partial<UnitSetting>; editedUnitSetting: Partial<UnitSetting> }) {
    if (defaultUnitSetting.unitId) {
      // PATCH existing
      const patchKeys = getPatchKeys(["terminalId", "terminalApiKey", "accountCardEnabled", "paymentTerminalEnabled"], defaultUnitSetting, editedUnitSetting);
      if (patchKeys.length) {
        let patchResponse = await BackendService.updateUnitSetting(defaultUnitSetting.unitId, getPatches({ keys: patchKeys, values: editedUnitSetting }));
        if (!patchResponse.isSuccess()) {
          throw new Error("Failed to update unit setting");
        }
      }
    } else {
      // POST new
      let postResponse = await BackendService.createUnitSetting({ ...editedUnitSetting, unitId } as UnitSetting);
      if (!postResponse.isSuccess()) {
        throw new Error("Failed to create new unit setting");
      }
    }
  }

  async function handleCreateSetupCode(unitId: string) {
    let unitSetupReponse = await BackendService.createUnitSetup(unitId);
    if (unitSetupReponse.isSuccess()) {
      setSetupCode(unitSetupReponse.data.token);
    } else {
      throw new Error("Failed to get unit setup");
    }
  }

  const loadExistingSetupCode = useCallback(async (unitId: string) => {
    let unitSetupReponse = await BackendService.getUnitSetup(unitId);
    if (unitSetupReponse.isSuccess()) {
      setSetupCode(unitSetupReponse.data.token);
    }
  }, []);

  return { setupCode, handleUnitSave, handleUnitSettingSave, handleCreateSetupCode, loadExistingSetupCode };
}
