import { useTranslation } from "react-i18next";

export default function ListHeader() {
  const { t } = useTranslation();

  return (
    <div className="list-header">
      <p>{t("List.Name")}</p>
      <p>{t("List.LastSeen")}</p>
    </div>
  );
}
