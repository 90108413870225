import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCustomDispatch } from "store/useStore";
import { appActions } from "store/app";

import Button from "components/ui/Button";
import Modal from "../Modal";
import { Form, Label, TextInput, Toggle } from "components/ui/forms";

import BackendService from "services/backend";

import useEditActions from "./useEditActions";

import { ReactComponent as ArrowIcon } from "assets/img/icons/arrow-left.svg";
import { ReactComponent as CloseIcon } from "assets/img/icons/close.svg";

import { Unit, UnitSetting } from "types";

import "./Edit.scss";

export default function UnitEdit({ unit, dismiss, handleFinalizedEdit }: { unit: Partial<Unit>; dismiss: () => void; handleFinalizedEdit: () => void }) {
  const { t } = useTranslation();

  const dispatch = useCustomDispatch();

  const isNew = !unit.id;

  const [isLoadingUnitSetting, setIsLoadingUnitSetting] = useState(true);

  const [defaultUnit] = useState(unit);
  const [editedUnit, setEditedUnit] = useState(defaultUnit);

  const [defaultUnitSetting, setDefaultUnitSetting] = useState<Partial<UnitSetting>>({});
  const [editedUnitSetting, setEditedUnitSetting] = useState<Partial<UnitSetting>>({});

  const [isLoading, setIsLoading] = useState(false);
  const [showInputErrors, setShowInputErrors] = useState(false);
  const [showSetupCodeModal, setShowSetupCodeModal] = useState(false);

  const { setupCode, handleUnitSave, handleUnitSettingSave, handleCreateSetupCode, loadExistingSetupCode } = useEditActions();

  const missingFields = useMemo(() => {
    let missingFieldsList: string[] = [];
    if (!editedUnit.name) {
      missingFieldsList.push("name");
    }
    if (!editedUnitSetting.terminalId) {
      missingFieldsList.push("terminalId");
    }
    if (!editedUnitSetting.terminalApiKey) {
      missingFieldsList.push("terminalApiKey");
    }
    return missingFieldsList;
  }, [editedUnit, editedUnitSetting]);

  const inputHasError = (name: string) => showInputErrors && missingFields.includes(name);

  useEffect(() => {
    async function loadUnitSetting(unitId: string) {
      let unitSettingResponse = await BackendService.getUnitSetting(unitId);
      if (unitSettingResponse.isSuccess()) {
        setDefaultUnitSetting(unitSettingResponse.data);
        setEditedUnitSetting(unitSettingResponse.data);
      }
      setIsLoadingUnitSetting(false);
    }

    if (unit.id) {
      loadUnitSetting(unit.id);
    } else {
      setIsLoadingUnitSetting(false);
    }
  }, [unit.id]);

  useEffect(() => {
    if (unit.id) {
      loadExistingSetupCode(unit.id);
    }
  }, [unit.id, loadExistingSetupCode]);

  async function handleSave() {
    if (missingFields.length > 0) {
      setShowInputErrors(true);
      return;
    }
    setShowInputErrors(false);
    setIsLoading(true);

    try {
      let unitId = await handleUnitSave({ defaultUnit, editedUnit });
      await handleUnitSettingSave({ unitId, defaultUnitSetting, editedUnitSetting });
      if (!defaultUnit.id) {
        await handleCreateSetupCode(unitId);
        setShowSetupCodeModal(true);
      } else {
        handleFinalizedEdit();
      }
    } catch (error) {
      console.error(error);
      dispatch(appActions.setError("Failed to save"));
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoadingUnitSetting) {
    return (
      <div className="edit">
        <p className="loading-text">{t("Loading")}</p>
      </div>
    );
  }

  return (
    <div className="edit">
      <div className="edit-header">
        <Button theme="text" onClick={dismiss}>
          <ArrowIcon className="arrow-icon" />
          <p>{t("Edit.AllUnits")}</p>
        </Button>
        <Button theme="text" onClick={dismiss}>
          <CloseIcon className="close-icon" />
        </Button>
      </div>
      <div className="edit-body">
        <div className="edit-body-title">
          <h3>{isNew ? t("Edit.NewUnit") : t("Edit.EditUnit")}</h3>
          {!!setupCode && <Button onClick={() => setShowSetupCodeModal(true)}>{t("Edit.ShowSetupCode")}</Button>}
        </div>
        <Form>
          <Label label={`${t("Edit.Name")}*`}>
            <TextInput value={editedUnit.name} hasError={inputHasError("name")} handleInputChange={(value) => setEditedUnit((prev) => ({ ...prev, name: value }))} />
          </Label>
          <Label label={t("Edit.ID")}>
            <TextInput value={editedUnit.id} />
          </Label>
          <Label label={`${t("Edit.TerminalId")}*`}>
            <TextInput value={editedUnitSetting.terminalId} hasError={inputHasError("terminalId")} handleInputChange={(value) => setEditedUnitSetting((prev) => ({ ...prev, terminalId: value }))} />
          </Label>
          <Label label={t("Edit.Version")}>
            <TextInput value={editedUnit.frontendVersion ? `${editedUnit.frontendVersion}` : undefined} />
          </Label>
          <Label label={`${t("Edit.TerminalApiKey")}*`}>
            <TextInput
              value={editedUnitSetting.terminalApiKey}
              hasError={inputHasError("terminalApiKey")}
              handleInputChange={(value) => setEditedUnitSetting((prev) => ({ ...prev, terminalApiKey: value }))}
            />
          </Label>
          <Label label={t("Edit.HALVersion")}>
            <TextInput value={editedUnit.halVersion || ""} />
          </Label>
        </Form>
        <Form>
          <Toggle
            toggleId="accountCardEnabled"
            isChecked={!!editedUnitSetting.accountCardEnabled}
            label={t("Edit.AccountCardEnabled")}
            handleToggleClick={(value) => setEditedUnitSetting((prev) => ({ ...prev, accountCardEnabled: value }))}
          />
          <Toggle
            toggleId="paymentTerminalEnabled"
            isChecked={!!editedUnitSetting.paymentTerminalEnabled}
            label={t("Edit.PaymentTerminalEnabled")}
            handleToggleClick={(value) => setEditedUnitSetting((prev) => ({ ...prev, paymentTerminalEnabled: value }))}
          />
        </Form>
      </div>
      <div className="edit-footer">
        <Button onClick={handleSave} attributes={{ disabled: isLoading }}>
          {isLoading ? t("Saving") : t("Save")}
        </Button>
        <Button theme="text" onClick={dismiss} attributes={{ disabled: isLoading }}>
          {t("Cancel")}
        </Button>
      </div>
      {!!setupCode && showSetupCodeModal && (
        <Modal>
          <div className="setup-code-modal-content">
            <h3>{t("Edit.SetupCode")}</h3>
            <p>{t("Edit.SetupCodeDescription")}</p>
            <h1>{setupCode}</h1>
            {isNew ? <Button onClick={handleFinalizedEdit}>{t("Continue")}</Button> : <Button onClick={() => setShowSetupCodeModal(false)}>{t("OK")}</Button>}
          </div>
        </Modal>
      )}
    </div>
  );
}
